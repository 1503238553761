import { asString } from '@komo-tech/core/utils/string';
import { Box } from '@komo-tech/ui/Box';
import { ErrorBoundary } from '@komo-tech/ui/ErrorBoundary';
import { useOnMount } from '@komo-tech/ui/hooks/useOnMount';
import { FCC } from 'fcc';
import { useRouter } from 'next/router';
import { Element, scroller } from 'react-scroll';

import { useCardShellColorVars } from '@/common/components/Card/shared/CardShell';
import { FrontCardErrored } from '@/common/components/Card/shared/Front/FrontCardErrored';
import { CardStatuses } from '@/common/models/CardStatuses';
import { FrontCardItem } from '@/front/components/site/FrontCardItem';
import { useFrontPage } from '@/front/components/site/SiteHost/Store';

const CardErrorBoundary: FCC = ({ children }) => (
  <ErrorBoundary fallback={<FrontCardErrored />}>{children}</ErrorBoundary>
);

export const CardList = () => {
  const page = useFrontPage((x) => x.page);
  useHandleScroll();

  const vars = useCardShellColorVars({
    backgroundColor: page.properties.CardShellBg,
    textColor: page.properties.CardShellC,
    borderColor: page.properties.CardShellBd
  });
  return (
    <Box w="100%" __vars={vars}>
      {page.cards.reduce((acc, c) => {
        if (c.status == CardStatuses.Published) {
          acc.push(
            <CardErrorBoundary key={c.id.toString()}>
              <Element name={c.id.toString()}>
                <FrontCardItem
                  cardId={c.id}
                  cardType={c.type}
                  imageAspectRatio={c.coverImage?.size}
                  shellBg={c.colors.shellBg}
                  shellBd={c.colors.shellBd}
                />
              </Element>
            </CardErrorBoundary>
          );
        }
        return acc;
      }, [])}
    </Box>
  );
};

const useHandleScroll = () => {
  const router = useRouter();

  //TODO DO WE USE THIS STILL?
  useOnMount(() => {
    const { scroll } = router.query;
    if (!scroll) {
      return;
    }
    scroller.scrollTo('site-footer', {
      delay: 0,
      smooth: 'easeOutQuart'
    });
  });

  //Will scroll to the card in q=scrollTo
  //Then removes the query param from router
  useOnMount(
    ({ hasQuery }) => {
      if (hasQuery) {
        delete router.query['scrollTo'];
        router.replace(
          {
            pathname: router.pathname,
            query: router.query
          },
          undefined,
          { shallow: true }
        );
      }
    },
    {
      resolveContextData: () => {
        let timeout: NodeJS.Timeout = null;

        const { scrollTo } = router.query;
        if (!scrollTo) {
          return { timeout, hasQuery: false };
        }

        timeout = setTimeout(() => {
          scroller.scrollTo(asString(scrollTo).toLowerCase(), {
            delay: 0,
            smooth: 'easeOutQuart',
            offset: -8
          });
        }, 2000 /* defer scroll for 2 seconds */);

        return { timeout, hasQuery: true };
      },
      cleanup: ({ timeout }) => {
        if (timeout) {
          clearTimeout(timeout);
        }
      }
    }
  );
};
