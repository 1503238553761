import { FC, useEffect } from 'react';

import { Page } from '@/common/models/pages/Page';
import { PageProperties } from '@/common/models/pages/shared/PageProperties';
import { Site } from '@/common/models/site/Site';
import { SiteProperties } from '@/common/models/site/SiteProperties';
import {
  FrontPageStore,
  FrontSiteStore,
  useFrontSite
} from '@/front/components/site/SiteHost/Store';

import { SiteHubMethods } from './_types';
import { useSiteHubSubscribe } from './useSiteHubSubscribe';

export const useSiteSignalRUpdateSync = () => {
  const subscribe = useSiteHubSubscribe();

  const enabled = useFrontSite(
    (x) => x.site.properties.RealtimeContentUpdatesEnabled
  );

  useEffect(() => {
    if (!enabled) {
      return () => {};
    }

    const siteUnsubscribe = subscribe(SiteHubMethods.OnSiteUpdated, (e) => {
      const site = FrontSiteStore.getState().site;
      FrontSiteStore.setState({
        site: new Site({
          ...site,
          properties: new SiteProperties({
            ...site.properties,
            ...e.siteUpdateProperties
          })
        })
      });
    });

    const pageUnsubscribe = subscribe(SiteHubMethods.OnPageUpdated, (e) => {
      const page = FrontPageStore.getState().page;
      if (e.pageId != page.id.toString()) {
        return;
      }

      FrontPageStore.setState({
        page: new Page({
          ...page,
          properties: new PageProperties({
            ...page.properties,
            ...e.pageUpdateProperties
          })
        })
      });
    });

    return () => {
      siteUnsubscribe();
      pageUnsubscribe();
    };
  }, [enabled]);
};

export const SiteSignalRUpdateSync: FC = () => {
  useSiteSignalRUpdateSync();
  return null;
};
