import { useQueryClient } from '@tanstack/react-query';
import differenceBy from 'lodash/differenceBy';

import { usePageCardsUpdatedSignalRSync } from '@/common/data/signal-r/SiteHub';
import { CardStatuses } from '@/common/models/CardStatuses';
import { Page } from '@/common/models/pages/Page';
import { PageCard } from '@/common/models/pages/PageCard';
import { FrontPageStore } from '@/front/components/site/SiteHost/Store';
import { PublicCardsService } from '@/front/data/Cards';

const CardCacheKeys = PublicCardsService.cacheKeys;

interface UsePageCardsUpdatedSyncOptions {
  onUpdate?: (cards: PageCard[]) => void;
}

export const useFrontPageCardsUpdatedSync = ({
  onUpdate
}: UsePageCardsUpdatedSyncOptions) => {
  const queryClient = useQueryClient();

  usePageCardsUpdatedSignalRSync({
    onPublish: (e) => {
      queryClient.setQueryData(
        CardCacheKeys.descriptor(e.cardId),
        e.cardDescriptor
      );
      queryClient.setQueryData(CardCacheKeys.typed(e.cardId), e.typedCard);

      let updated = false;
      const pageState = FrontPageStore.getState().page;
      const cards = pageState.cards.reduce((acc, card) => {
        if (card.id.equals(e.cardId)) {
          acc.push({
            ...card,
            status: e.cardDescriptor.status
          });
          updated = true;
        } else {
          acc.push(card);
        }
        return acc;
      }, [] as PageCard[]);

      // if a card is unpublished we don't notify
      if (e.isPublished && updated) {
        onUpdate?.(cards);
      }
    },
    onAdded: (e) => {
      const pageState = FrontPageStore.getState().page;
      if (e.pageId !== pageState.id.toString()) {
        return;
      }

      const newCards = differenceBy(e.cards, pageState.cards, (c) =>
        c.id.toString()
      );
      const hasNewPublishedCards = newCards.some(
        (c) => c.status == CardStatuses.Published
      );

      if (hasNewPublishedCards) {
        onUpdate?.(e.cards);
      } else {
        // update store silently
        FrontPageStore.setState({
          page: new Page({
            ...pageState,
            cards: e.cards
          })
        });
      }
    }
  });
};
