import { asBoolean } from '@komo-tech/core/utils/boolean';
import { asString } from '@komo-tech/core/utils/string';
import { classFromJson } from '@komo-tech/core/utils/type';
import { useFlag } from '@komo-tech/ui/hooks/useFlag';
import { NoSsr } from '@komo-tech/ui/NoSsr';
import { resolveThemeForProvider } from '@komo-tech/ui/theme/Default';
import addSeconds from 'date-fns/addSeconds';
import { parseUrl } from 'next/dist/shared/lib/router/utils/parse-url';
import { GetServerSidePropsContext } from 'next/types';
import nookies, { setCookie } from 'nookies';
import { useEffect, useMemo } from 'react';

import { CustomFontsHead } from '@/common/components/CustomFontsHead';
import { MetaTags } from '@/common/components/MetaTags';
import { LoadingPage } from '@/common/components/Pages/LoadingPage';
import { PageLayout } from '@/common/layouts';
import { ClientRequestInfo } from '@/common/models/ClientRequestInfo';
import { Page } from '@/common/models/pages/Page';
import { PageStatuses } from '@/common/models/pages/shared/PageStatuses';
import { Site } from '@/common/models/site/Site';
import { SiteStatuses } from '@/common/models/site/SiteStatuses';
import { SiteUser } from '@/common/models/SiteUser';
import { getHostNameForServer } from '@/common/utils/HostProvider';
import {
  siteNotFoundServerSide,
  tryGetHomepageAsync,
  tryGetPageFromSlugAsync,
  tryGetRequestInfo,
  tryGetSiteFromHostAsync
} from '@/common/utils/SiteHostSeverSideFunctions';
import { SiteDraftViewPasswordPage } from '@/front/components/shared/SiteDraftViewPasswordPage';
import { IntegrationService } from '@/front/data/IntegrationService/IntegrationService';
import { CookieConstants } from '@/front/utils/Cookies/CookieConstants';
import { handleSsrUserCookiesAsync } from '@/front/utils/Cookies/CookieFunctions';

import { SiteHost } from './SiteHost';

export interface SitePageProps {
  // Not typed due to getServerSideProps not handling JSON
  // serialisation properly
  siteJson?: string;
  pageJson?: string;
  requiresPassword?: string;
  requestInfoJson?: string;
  userJson?: string;
}

export const SitePage = ({
  siteJson,
  pageJson,
  requiresPassword,
  requestInfoJson,
  userJson
}: SitePageProps) => {
  const loggedInUser = useMemo(
    () => classFromJson(SiteUser, userJson),
    [userJson]
  );

  const site = useMemo(() => new Site(JSON.parse(siteJson)), [siteJson]);
  const page = useMemo(() => new Page(JSON.parse(pageJson)), [pageJson]);
  const meta = page.getMetaValues(site);

  const requiresPasswordFlag = useFlag(asBoolean(requiresPassword));

  const clientRequestInfo: ClientRequestInfo = useMemo(
    () => ClientRequestInfo.fromJson(requestInfoJson),
    [requestInfoJson]
  );

  useEffect(() => {
    const initIntegrations = () => {
      const integrationService = new IntegrationService({
        integrations: IntegrationService.buildIntegrationConfig(site),
        debug: false
      });

      integrationService.loadAsync();
    };

    initIntegrations();
  }, [site, site?.properties?.CookieConsentEnabled]);

  return (
    <PageLayout
      title={meta.metaTitle}
      theme={resolveThemeForProvider({
        properties: site?.properties,
        fontFamily: site?.properties?.Font
      })}
      forceColorScheme="light"
    >
      <MetaTags meta={meta} />
      <CustomFontsHead
        customFontCss={site.customFontCss}
        preloads={site.preloadFontInfo}
      />
      <NoSsr onSsr={<LoadingPage />}>
        {requiresPasswordFlag.value && (
          <SiteDraftViewPasswordPage
            site={site}
            page={page}
            onPasswordSuccess={(password) => {
              setCookie(null, CookieConstants.DraftPassword.name, password, {
                expires: addSeconds(
                  new Date(),
                  CookieConstants.DraftPassword.defaultMaxAgeSeconds
                )
              });
              requiresPasswordFlag.setFalse();
            }}
          />
        )}
        {!requiresPasswordFlag.value && (
          <SiteHost
            site={site}
            page={page}
            clientRequestInfo={clientRequestInfo}
            user={loggedInUser}
          />
        )}
      </NoSsr>
    </PageLayout>
  );
};

const isPortalUrl = (hostname: string) =>
  hostname === 'app.komo.tech' ||
  hostname === 'portal.komo.digital' ||
  hostname === 'portal.komo.site' ||
  hostname === 'app.staging.komo.tech' ||
  hostname === 'portal.staging.komo.digital' ||
  hostname === 'portal.staging.komo.site';

// TODO: move inline if no errors seen in production
const shouldRedirectToPortalSites = (context: GetServerSidePropsContext) => {
  try {
    return (
      isPortalUrl(getHostNameForServer(context.req)) &&
      parseUrl(context.resolvedUrl).pathname == '/'
    );
  } catch (err) {
    console.error('Failed to check if request is portal root request', err);
    return false;
  }
};

export const getSsrPropsAsync = async (context: GetServerSidePropsContext) => {
  if (shouldRedirectToPortalSites(context)) {
    return {
      redirect: {
        destination: '/portal/workspaces',
        permanent: false
      }
    };
  }

  const props: Partial<SitePageProps> = {};
  const site = await tryGetSiteFromHostAsync(context);

  if (
    !site ||
    site.status === SiteStatuses.Archived ||
    site.status === SiteStatuses.Deleted
  ) {
    return siteNotFoundServerSide();
  }

  props.siteJson = JSON.stringify(site);

  const { query } = context;
  const page = query.slug
    ? await tryGetPageFromSlugAsync(site, asString(query.slug))
    : await tryGetHomepageAsync(site);

  if (
    !page ||
    page.status === PageStatuses.Archived ||
    page.status === PageStatuses.Deleted
  ) {
    return {
      notFound: true as const
    };
  }

  props.pageJson = JSON.stringify(page);

  const requestInfo = tryGetRequestInfo(context.req);
  props.requestInfoJson = JSON.stringify(requestInfo);

  const cookies = nookies.get(context);

  if (!requestInfo.isBot) {
    const user = await handleSsrUserCookiesAsync({
      siteId: site.id,
      cookies,
      context,
      userCreationEnabled: site.properties.ServerSideUserCreationEnabled
    });
    props.userJson = !user ? null : JSON.stringify(user);
  }

  const draftPasswordIsCorrect = site.draftPasswordMatches(
    cookies[CookieConstants.DraftPassword.name]
  );

  if (
    (site.status != SiteStatuses.Draft && page.status != PageStatuses.Draft) ||
    draftPasswordIsCorrect
  ) {
    props.requiresPassword = 'false';
  } else {
    props.requiresPassword = 'true';
  }

  return { props };
};
