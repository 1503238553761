import { DomPortalProvider } from '@komo-tech/ui/Portal/DomPortal';
import React, { FC } from 'react';

import { LoadingPage } from '@/common/components/Pages/LoadingPage';
import { SiteSignalRUpdateSync } from '@/common/data/signal-r/SiteHub';
import { SiteHubSignalRProvider } from '@/common/data/signal-r/SiteHub/SiteHubSignalRProvider';
import { usePrefillFormValuesFromRoute } from '@/common/hooks/usePrefillFormValuesFromRoute';
import { ClientRequestInfo } from '@/common/models/ClientRequestInfo';
import { Page } from '@/common/models/pages/Page';
import { Site } from '@/common/models/site/Site';
import { SiteUser } from '@/common/models/SiteUser';
import { KomoDebugProvider } from '@/front/components/komo-debug/KomoDebugContext';
import { FrontSessionActivityPoller } from '@/front/components/site/user-session-v2/FrontSessionActivityPoller';
import { IdleTimerStoreSync } from '@/front/components/site/user-session-v2/IdleTimerStoreSync';
import { PublicEventService } from '@/front/data/PublicEventService';

import { UserProvider } from '../UserContext';
import { ConsentBanner } from './ConsentBanner';
import { SiteErrored } from './SiteErrored';
import { SiteFontHeadStyle } from './SiteFontHeadStyle';
import { SiteHostContent } from './SiteHostContent';
import { SiteScript } from './SiteScript';
import { useFrontSite } from './Store';
import { useInitFrontPage } from './Store/useInitFrontPage';
import { useInitFrontSite } from './Store/useInitFrontSite';
import { useInitSiteUser } from './useInitSiteUser';

export interface SiteHostProps {
  site: Site;
  page?: Page;
  user?: SiteUser;
  clientRequestInfo?: ClientRequestInfo;
}

export const SiteHost: FC<SiteHostProps> = ({
  site: siteProp,
  page,
  user,
  clientRequestInfo = {}
}) => {
  useInitFrontSite({ site: siteProp, clientRequestInfo });
  const site = useFrontSite((x) => x.site);

  useInitFrontPage({ page });

  const [currentUser, isLoading] = useInitSiteUser({
    site,
    siteUser: user,
    clientRequestInfo,
    onTrackViewed: ({ initialQueryParams, clientRequestInfo }) =>
      PublicEventService.trackPageViewedAsync(
        site,
        initialQueryParams,
        clientRequestInfo
      )
  });

  usePrefillFormValuesFromRoute({
    key: 'f:'
  });

  return (
    <KomoDebugProvider>
      <SiteScript location="Head" />
      <SiteFontHeadStyle />
      {isLoading && <LoadingPage />}
      {!isLoading && !currentUser && <SiteErrored />}
      {!isLoading && currentUser && (
        <UserProvider user={currentUser}>
          <FrontSessionActivityPoller />
          <IdleTimerStoreSync timeout={15_000} startOnMount />
          <SiteHubSignalRProvider>
            <SiteSignalRUpdateSync />
            <DomPortalProvider>
              <SiteHostContent />
            </DomPortalProvider>
          </SiteHubSignalRProvider>
        </UserProvider>
      )}
      <ConsentBanner />
      <SiteScript location="Body" />
    </KomoDebugProvider>
  );
};
