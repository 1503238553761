import { Properties as CSSProperties } from 'csstype';

export const formatBackgroundImageUrl = (backgroundImageUrl: string) =>
  `url(${backgroundImageUrl})`;

export const formatBackgroundFilter = (
  backgroundFilter: string
) => `linear-gradient(
      ${backgroundFilter},
      ${backgroundFilter}
    )`;

interface FormatProps {
  backgroundFilter?: string;
  backgroundImageUrl?: string;
}

/**
 * Combine a background image URL and a background filter RGBA value
 * to set a "background-image" value.
 *
 * @param props
 * @example
 *
 background-image: ${(p) =>
    formatBackgroundAndFilter({backgroundFilter: 'rgba(255,0,255,0.4)', backgroundImageUrl: 'https://www.timeitel.com/jagonaught.jpg'})};
 *
 */
export const formatBackgroundAndFilter = (props?: FormatProps) => {
  const backgroundParts = [];
  if (props?.backgroundFilter) {
    backgroundParts.push(formatBackgroundFilter(props?.backgroundFilter));
  }

  if (props?.backgroundImageUrl) {
    backgroundParts.push(formatBackgroundImageUrl(props?.backgroundImageUrl));
  }

  return backgroundParts.join(',');
};

export interface StyledBackgroundProps {
  imageUrl?: string;
  color?: string;
  filterRgb?: string;
}

export const backgroundStyle = (
  p: StyledBackgroundProps
): CSSProperties<string | number> => ({
  background: backgroundCssValue(p),
  backgroundColor: p.color ? p.color : '#FFFFFF'
});

export const backgroundCssValue = (
  p: Omit<StyledBackgroundProps, 'backgroundColor'>
) => `${formatBackgroundAndFilter({
  backgroundFilter: p.filterRgb,
  backgroundImageUrl: p.imageUrl
})}
    no-repeat center center/cover fixed`;
