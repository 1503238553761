import { GetServerSideProps, NextPage } from 'next';
import React from 'react';

import {
  getSsrPropsAsync,
  SitePage,
  SitePageProps
} from '@/front/components/site/SiteHost/SitePage';

const Index: NextPage<SitePageProps> = (props) => {
  return <SitePage {...props} />;
};

export default Index;

export const getServerSideProps: GetServerSideProps = (context) => {
  return getSsrPropsAsync(context);
};
