import { FC } from 'react';

import { useFrontPage, useFrontSite } from '../../Store';
import { BaseDraftBanner } from './_BaseDraftBanner';

export const SiteDraftBanner: FC = () => {
  const isDraftSite = useFrontSite((x) => x.site.isDraft);
  const isDraftPage = useFrontPage((x) => x.page?.isDraft);

  if (!isDraftSite && !isDraftPage) {
    return null;
  }

  return <BaseDraftBanner />;
};
