import { Box } from '@komo-tech/ui/Box';
import { Button } from '@komo-tech/ui/Button';
import { FormCheckbox } from '@komo-tech/ui/Form/Checkbox';
import { Modal } from '@komo-tech/ui/Modal';
import { Text } from '@komo-tech/ui/Text';
import differenceInYears from 'date-fns/differenceInYears';
import parse from 'date-fns/parse';
import { CSSProperties, memo, useState } from 'react';

import { CardHeader } from '@/common/components/Card/shared/CardHeader';
import { Image as ImageElement } from '@/common/components/Image';
import { SiteEntryConditionsKinds } from '@/common/models/site/SiteEntryConditionsKinds';
import { PublicSiteService } from '@/front/data/Sites';

import {
  useFrontPage,
  useFrontSite
} from '@/front/components/site/SiteHost/Store';
import { useUserContextSelector } from '../UserContext';
import { EntryConditionDateInput } from './EntryConditionDateInput';
import { EntryConditionScreen } from './EntryConditionScreen';

export type SubmissionType = {
  type: SiteEntryConditionsKinds;
  age?: string;
  checked?: boolean;
};

export const FrontEntryConditionScreen = memo(() => {
  const siteProperties = useFrontSite((x) => x.site.properties);
  const pageProperties = useFrontPage((x) => x.page?.properties);
  const siteId = useFrontSite((x) => x.site.id);
  const setUser = useUserContextSelector((s) => s.setUser);
  const [hasError, setError] = useState(false);
  const [entrySubmission, setEntrySubmission] = useState<SubmissionType>({
    type: siteProperties.EntryConditionsType
  });

  const checkSubmission = async () => {
    if (siteProperties.EntryConditionsType === SiteEntryConditionsKinds.Date) {
      const date = parse(entrySubmission.age, 'dd/MM/yyyy', new Date());
      const age = differenceInYears(new Date(), date);
      if (age < siteProperties.EntryConditionsMinimumAge || isNaN(age)) {
        setError(true);
        return;
      }
    } else if (
      siteProperties.EntryConditionsType === SiteEntryConditionsKinds.Check
    ) {
      if (!entrySubmission.checked) {
        setError(true);
        return;
      }
    }
    const checkEntry = await PublicSiteService.actions.checkEntryConditionAsync(
      {
        siteId,
        entryCondition: entrySubmission.age || `${entrySubmission.checked}`
      }
    );
    if (checkEntry.conditionPassed) {
      setUser(checkEntry.currentUser);
      return;
    }
    setError(true);
    return;
  };

  const hasLogo = !!siteProperties.EntryConditionsLogoImage?.url;

  const { AccentBackgroundColor, AccentTextColor } =
    pageProperties ?? siteProperties;

  return (
    <Modal isOpen size={'FullScreen'}>
      <Box h="100vh" w="100vw" style={{ overflowY: 'auto' }}>
        <EntryConditionScreen
          siteProperties={siteProperties}
          onSubmit={checkSubmission}
        >
          {hasLogo && (
            <Box flex="1 1 auto" mb={'1.5rem'}>
              <ImageElement
                image={siteProperties.EntryConditionsLogoImage}
                objectFit="cover"
                style={imageStyle}
              />
            </Box>
          )}
          {siteProperties.EntryConditionsHeader && (
            <CardHeader
              variant="h3"
              mb={'1.5rem'}
              c={siteProperties.EntryConditionsTextColor}
              ta={'center'}
            >
              {siteProperties.EntryConditionsHeader}
            </CardHeader>
          )}
          {siteProperties.EntryConditionsType ===
            SiteEntryConditionsKinds.Date && (
            <>
              {siteProperties.EntryConditionsText && (
                <Text
                  size="lg"
                  mb={'1.5rem'}
                  c={siteProperties.EntryConditionsTextColor}
                  ta={'center'}
                >
                  {siteProperties.EntryConditionsText}
                </Text>
              )}

              <EntryConditionDateInput
                onChange={(_, v) =>
                  setEntrySubmission((e) => ({ ...e, age: v }))
                }
                mb="md"
              />
            </>
          )}

          {siteProperties.EntryConditionsType ===
            SiteEntryConditionsKinds.Check && (
            <Box flex="1 1 auto" mb={'1rem'}>
              <FormCheckbox
                size="md"
                withAnimation
                label={{
                  type: 'Content',
                  content: (
                    <Text
                      c={siteProperties.EntryConditionsTextColor}
                      ta={'center'}
                      size="lg"
                    >
                      {siteProperties.EntryConditionsText}
                    </Text>
                  )
                }}
                checked={entrySubmission.checked}
                onChange={({ currentTarget }) => {
                  setEntrySubmission((e) => ({
                    ...e,
                    checked: currentTarget.checked
                  }));
                }}
              />
            </Box>
          )}

          {hasError && siteProperties.EntryConditionsFeedbackText && (
            <Text
              size="sm"
              maw={200}
              mt="-1rem"
              mb="1rem"
              c="inherit"
              ta="center"
            >
              {siteProperties.EntryConditionsFeedbackText}
            </Text>
          )}

          <Box maw={200} w="100%">
            <Button
              w="100%"
              size="lg"
              variant="styled"
              styledVariantProps={{
                bgColor:
                  siteProperties.EntryConditionsButtonBackgroundColor ||
                  AccentBackgroundColor,
                color:
                  siteProperties.EntryConditionsButtonColor || AccentTextColor
              }}
              onClick={() => {
                checkSubmission();
              }}
            >
              {siteProperties.EntryConditionsButtonText}
            </Button>
          </Box>
        </EntryConditionScreen>
      </Box>
    </Modal>
  );
});

const imageStyle: CSSProperties = { maxWidth: '100%', maxHeight: '100%' };
