import React, { createContext, useContext } from 'react';

import { FCC } from '../../fcc';

export interface ScrollContainerContextState {
  containerId?: string;
}

export const ScrollContainerContext =
  createContext<ScrollContainerContextState>({
    containerId: undefined
  });
ScrollContainerContext.displayName = 'ScrollContainerContext';

interface Props {
  containerId?: string;
}

export const ScrollContainerProvider: FCC<Props> = ({
  containerId,
  children
}) => {
  return (
    <ScrollContainerContext.Provider
      value={{
        containerId
      }}
    >
      {children}
    </ScrollContainerContext.Provider>
  );
};

/**
 * Hook for obtaining the optional scroll container context.
 */
export const useScrollContainerContext = (): ScrollContainerContextState => {
  const context = useContext(ScrollContainerContext);
  return context;
};
